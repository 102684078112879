<template>
  <div p="x-6 t-8 b-6" bg="black">
    <div max="w-260" m="x-auto">
      <div class="flex">
        <div class="flex-1 text-base text-white">
          <div font="800" m="b-4">
            {{ t('key.footer.company') }}
          </div>
          <ul class="space-y-6">
            <li v-for="link in aboutLinks" :key="link.text">
              <NuxtLink :to="link.url">
                <span class="text-white underline">{{ link.text }}</span>
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div class="flex-1 text-base text-white">
          <div font="800" m="b-4">
            {{ t('key.footer.help') }}
          </div>
          <ul class="space-y-6">
            <li>
              <NuxtLink to="mailto:help@azgotrip.com" target="_self" class="text-gray-700 hover:text-gray-900">
                <span class="text-white underline">{{ t('key.footer.contact.us') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink to="mailto:help@azgotrip.com" target="_self" class="text-gray-700 hover:text-gray-900">
                <span class="text-white underline">{{ t('key.footer.faqs') }}</span>
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div class="flex-1 text-base text-white">
          <div font="800" m="b-4">
            {{ t('key.footer.partner.with.us') }}
          </div>
          <ul class="space-y-6">
            <li>
              <NuxtLink to="#" target="_self" class="text-gray-700 hover:text-gray-900">
                <span class="text-white underline">{{ t('key.footer.become.partner') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink to="#" target="_self" class="text-gray-700 hover:text-gray-900">
                <span class="text-white underline">{{ t('key.footer.become.distributor') }}</span>
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>

      <div m="b-10 t-5" class="h-1px bg-[#B3B3B3]"></div>

      <!-- Footer Section -->
      <footer class="mb-4 mt-8 text-center text-base text-white">
        <div class="mb-2">
          <a href="#" class="text-white">{{ t('key.footer.terms') }}</a> |
          <a href="#" class="text-white">{{ t('key.footer.privacy.policy') }}</a>
        </div>
        <div>
          <p>{{ t('key.footer.copyright') }}</p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

const aboutLinks = [
  { text: t('key.footer.about.us'), url: 'https://corporate.azgotrip.com/about-6' },
  { text: t('key.footer.careers'), url: 'https://corporate.azgotrip.com/contact' },
  // { text: t('key.footer.partnerships'), url: 'https://corporate.azgotrip.com/general-4' },
  // { text: t('key.footer.products'), url: 'https://corporate.azgotrip.com/about' },
  { text: t('key.footer.press'), url: 'https://corporate.azgotrip.com/press-media-coverage' },
  // { text: t('key.footer.campaigns'), url: 'https://corporate.azgotrip.com/' },
]
</script>

<style lang="scss">
.az-collapse {
  .van-cell {
    padding: 10px 0;
  }

  .van-collapse-item {
    &__content {
      padding: 0 0 12px 0;
    }

    &__title--expanded:after {
      display: none;
    }

    &--border:after {
      left: 0;
      right: 0;
    }
  }
}
</style>
