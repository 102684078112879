<template>
  <div class="relative pt-6 sm:pt-10" m="x-8" max-w="280">
    <div class="mb-10 text-center font-700" text="2xl sm:3xl black">
      {{ t('key.search.destination.featured') }}
    </div>
    <div ref="el" w="full" overflow-x="auto" class="popular_destination">
      <div class="space-x-5" flex="~ items-center">
        <div v-for="item in popularCities" :key="item.countryCode" class="relative mb-3" cursor="pointer" @click="onConfirmCity(item)">
          <van-image
            :src="item.img"
            :width="248"
            :height="180"
            :radius="18"
          />
          <div text="xl black" font="500" m="t-4">
            {{ item.destinationName }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="!arrivedState.right" class="absolute right--6 top-1/2 shadow-[0px_0.87px_15px_0px_#71717159]" flex="~ items-center justify-center" w="12" h="12" rounded="full" bg="white" cursor="pointer" @click="x += width / 3 * 2">
      <div class="i-local-arrow_right" text="lg #8D8D8D"></div>
    </div>
    <div v-if="!arrivedState.left" class="absolute left--6 top-1/2 shadow-[0px_0.87px_15px_0px_#71717159]" flex="~ items-center justify-center" w="12" h="12" rounded="full" bg="white" cursor="pointer" @click="x -= width / 3 * 2">
      <div class="i-local-arrow_right" rotate="180" text="lg #8D8D8D"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
const el = ref<HTMLElement | null>(null)

const { x, arrivedState } = useScroll(el, { behavior: 'smooth' })
const { width } = useElementSize(el)

const { t } = useI18n()
const { onConfirmCity } = useSearchCity({ refrehsOnConfirm: true })

const commonStore = useCommonStore()

const popularCities = computed(() =>
  commonStore.commonData.cityInfo?.recommendDestinationList,
)
</script>

<style>
.popular_destination::-webkit-scrollbar {
  display: none;
}
</style>
